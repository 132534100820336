






















































































































import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import { dispatch } from "@/store";

export default Vue.extend({
  data() {
    return {
      orderNoVisible: false,
      orderNo: "",
      email: "",
      platform: "H5",
      visible: false,
      preview: false,
      showSubmit: false,
      key: "top",
      left: "A",
      top: "",
      right: "M",
      charList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "+",
        "-",
        "五角星",
        "闪电",
        "爱心",
        "上箭头",
        "下箭头",
        "四点",
        "向左",
        "向右",
        "菱形",
        "对",
        "错",
        "美元",
        "十字架"
      ],
      chars: {
        A: "A",
        B: "B",
        C: "C",
        D: "D",
        E: "E",
        F: "F",
        G: "G",
        H: "H",
        I: "I",
        J: "J",
        K: "K",
        L: "L",
        M: "M",
        N: "N",
        O: "O",
        P: "P",
        Q: "Q",
        R: "R",
        S: "S",
        T: "T",
        U: "U",
        V: "V",
        W: "W",
        X: "X",
        Y: "Y",
        Z: "Z",
        "0": "0",
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",
        "6": "6",
        "7": "7",
        "8": "8",
        "9": "9",
        "+": "&#xe602;",
        "-": "&#xe603;",
        五角星: "&#xe613;",
        闪电: "&#xe610;",
        爱心: "&#xe612;",
        上箭头: "&#xe60f;",
        下箭头: "&#xe60e;",
        四点: "&#xe606;",
        向左: "&#xe601;",
        向右: "&#xe600;",
        菱形: "&#xe611;",
        对: "&#xe650;",
        错: "&#xe62e;",
        美元: "&#xe607;",
        十字架: "&#xe605;"
      }
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapState("app", {
      isMobile: "isMobile",
      isCN: "isCN"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    })
  },
  methods: {
    onOk() {
      if (!this.orderNo.trim()) {
        this.$message.info(
          "" + this.$t("inputError", [this.$t("AM65LessCustom.orderNo")])
        );
        return;
      }
      const emailRe = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRe.test(this.email)) {
        this.$message.info("" + this.$t("inputError", [this.$t("email")]));
        return;
      }
      this.platform = "shopify";
      dispatch
        .AMAfaCustom({
          orderNo: this.orderNo,
          platform: this.platform
        })
        .then(res => {
          this.visible = true;
          this.orderNoVisible = false;
          this.showSubmit = true;
          this.left = res.custom_info.left;
          this.top = res.custom_info.top;
          this.right = res.custom_info.right;
        })
        .catch(() => {
          this.$message.destroy();
          this.visible = true;
          this.orderNoVisible = false;
          this.showSubmit = true;
        });
    },
    onTopInput(text: string) {
      this.top = text
        .toUpperCase()
        .replace(/[^0-9A-Z,.!?~+-=±×÷ |/\\]/g, "")
        .replace("<", "")
        .slice(0, 20);

      console.log(this.left, this.top, this.right);
    },
    onTopExplain() {
      this.$confirm({
        title: this.$t("AM65LessCustom.explainTitle") + "",
        class: "top-explain-modal",
        content: (h: any) => {
          return h("div", { style: "text-align:center;" }, [
            h("div", this.$t("AM65LessCustom.explainText01")),
            h("br"),
            h("div", this.$t("AM65LessCustom.explainText02")),
            h("div", ", . ! ? + - × ÷ = ± ~ | / \\"),
            h("br"),
            h("div", this.$t("AM65LessCustom.explainText03")),
            h("br"),
            this.isCN
              ? h("img", {
                  style: "width:2rem",
                  attrs: { src: this.oss.qr_code_wechat }
                })
              : h(
                  "a",
                  {
                    attrs: { href: this.url.discord, target: "_blank" }
                  },
                  [
                    h("img", {
                      style: "width:2rem",
                      attrs: { src: this.oss.qr_code_discord }
                    })
                  ]
                ),
            h("br"),
            h("br"),
            h("div", this.$t("AM65LessCustom.explainText04"))
          ]);
        },
        width: "600px",
        centered: true,
        okText: this.$t("ok") + ""
      });
    },
    onSubmit() {
      const { top, left, right, orderNo, email, platform } = this;
      if (!top) {
        this.$message.info(this.$t("AM65LessCustom.confirmTip01") + "");
        this.key = "top";
        return;
      }
      if (!left) {
        this.$message.info(this.$t("AM65LessCustom.confirmTip02") + "");
        this.key = "left";
        return;
      }
      if (!right) {
        this.$message.info(this.$t("AM65LessCustom.confirmTip03") + "");
        this.key = "right";
        return;
      }

      this.$confirm({
        title: this.$t("AM65LessCustom.confirmTitle") + "",
        content: (h: any) => {
          return h("ol", { style: "padding: 0.5rem 0.8rem;" }, [
            h("li", this.$t("AM65LessCustom.confirmText01")),
            h("br"),
            h("li", this.$t("AM65LessCustom.confirmText02")),
            h("br"),
            h("li", this.$t("AM65LessCustom.confirmText03")),
            h("br"),
            h("li", this.$t("AM65LessCustom.confirmText04"))
          ]);
        },
        width: "500px",
        centered: true,
        okText: this.$t("AM65LessCustom.confirm") + "",
        cancelText: this.$t("cancel") + "",
        onOk: () => {
          dispatch
            .AMAfaCustomPost({
              custom_info: { top, left, right },
              order_no: orderNo,
              email,
              platform
            })
            .then(() => {
              this.$message.info(
                this.$t("AM65LessCustom.submitSuccessfully") + ""
              );
            });
        }
      });
    }
  },
  mounted() {
    this.orderNo = String(this.$route.query.order_no || "");
    if (this.orderNo === "input") {
      if (!this.user.isSignIn) {
        dispatch.userSignIn();
        return;
      }
      this.orderNo = "";
      this.orderNoVisible = true;
    } else if (this.orderNo) {
      if (!this.user.isSignIn) {
        dispatch.userSignIn();
        return;
      }
      this.visible = true;
      dispatch
        .AMAfaCustom({
          orderNo: this.orderNo,
          platform: this.platform
        })
        .then(res => {
          this.left = res.custom_info.left;
          this.top = res.custom_info.top;
          this.right = res.custom_info.right;
          dispatch.order(this.orderNo).then(res => {
            if (
              moment()
                .subtract(7, "days")
                .isAfter(res.order.create_time)
            ) {
              this.$message.info(this.$t("AM65LessCustom.timeOut") + "");
              this.showSubmit = true;
            } else {
              this.showSubmit = true;
            }
          });
        })
        .catch(() => {
          this.$message.destroy();
          this.$message.info(this.$t("AM65LessCustom.orderNoError") + "");
          this.preview = true;
        });
    } else {
      this.visible = true;
      this.preview = true;
    }
  }
});
